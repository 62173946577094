/*
ENVIRONMENT SPECIFIC CONFIGURATION

TO ADD A NEW ENVIRONMENT VARIABLE:

1. Add the environment variable here.
2. Add the environment variable to the .env file in the root of the project. ENSURE IT STARTS WITH VITE_.
3. Add the environment variable to /helm/checkout-ui/templates/deployment.yaml to be picked up by the deployment
4. Optionally, add the environment variable to your .env.development or .env.local file for local development override

For more information see https://github.com/quantium-enterprise/checkout-app/pull/7161
*/

const isDevelopment = Boolean(import.meta.env.DEV);
const isProduction = Boolean(import.meta.env.PROD);

export const ENVIRONMENT: Record<string, boolean | string> = {
  VITE_FOLDERS_FEATURE_ENABLED: isDevelopment
    ? import.meta.env.VITE_FOLDERS_FEATURE_ENABLED
    : "#VITE_FOLDERS_FEATURE_ENABLED#",
  VITE_AMPLITUDE_API_KEY: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_API_KEY
    : "#VITE_AMPLITUDE_API_KEY#",
  VITE_SEGMENT_API_KEY: isDevelopment
    ? import.meta.env.VITE_SEGMENT_API_KEY
    : "#VITE_SEGMENT_API_KEY#",
  VITE_AMPLITUDE_EVENT_UPLOAD_PERIOD_MILLIS: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_EVENT_UPLOAD_PERIOD_MILLIS
    : "#VITE_AMPLITUDE_EVENT_UPLOAD_PERIOD_MILLIS#",
  VITE_AMPLITUDE_EVENT_UPLOAD_THRESHOLD: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_EVENT_UPLOAD_THRESHOLD
    : "#VITE_AMPLITUDE_EVENT_UPLOAD_THRESHOLD#",
  VITE_AMPLITUDE_LOG_LEVEL: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_LOG_LEVEL
    : "#VITE_AMPLITUDE_LOG_LEVEL#",
  VITE_AMPLITUDE_SERVER_ZONE: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_SERVER_ZONE
    : "#VITE_AMPLITUDE_SERVER_ZONE#",
  VITE_AMPLITUDE_EVENT_BATCHING_ENABLED: isDevelopment
    ? import.meta.env.VITE_AMPLITUDE_EVENT_BATCHING_ENABLED
    : "#VITE_AMPLITUDE_EVENT_BATCHING_ENABLED#",
  VITE_TITLE: isDevelopment ? import.meta.env.VITE_TITLE : "#VITE_TITLE#",
  VITE_ENABLE_APPCUES: isDevelopment
    ? import.meta.env.VITE_ENABLE_APPCUES
    : "#VITE_ENABLE_APPCUES#",
  VITE_APPCUES_SCRIPT_ID: isDevelopment
    ? import.meta.env.VITE_APPCUES_SCRIPT_ID
    : "#VITE_APPCUES_SCRIPT_ID#",
  VITE_APPCUES_SETTINGS_SCRIPT_ID: isDevelopment
    ? import.meta.env.VITE_APPCUES_SETTINGS_SCRIPT_ID
    : "#VITE_APPCUES_SETTINGS_SCRIPT_ID#",
  VITE_APPCUES_ACCOUNT_ID: isDevelopment
    ? import.meta.env.VITE_APPCUES_ACCOUNT_ID
    : "#VITE_APPCUES_ACCOUNT_ID#",
  // Doesn't start with VITE_ so BASE_URL ignored in local development
  BASE_URL: isDevelopment ? "/" : "#BASE_URL#",
  VITE_ENV: isDevelopment ? import.meta.env.VITE_ENV : "#VITE_ENV#",
  VITE_PROJECT: isDevelopment ? import.meta.env.VITE_PROJECT : "#VITE_PROJECT#",
  IS_DEVELOPMENT: isDevelopment,
  IS_PRODUCTION: isProduction,
};
